import { Fade, Modal } from "@mui/material";
import Button, { buttonTypes } from "components/common/Button";
import { imageBasePath } from "lib/utils/imageUtils";
import Image from "next/image";

function ConfirmationModal({ isDialogOpen, onClose, onConfirm }) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="flex justify-center items-center"
      open={isDialogOpen}
      onClose={onClose}
      closeAfterTransition
    >
      <Fade in={true}>
        <div className="px-6 py-8 md:p-8 rounded bg-white max-w-lg text-left w-[95%]">
          <Image
            className="block"
            src={`${imageBasePath}/assets/icons/warning-icon.svg`}
            width={45}
            height={45}
            alt="warning icon"
          />
          <h2 className="font-semibold my-4 text-2xl">Are you Sure?</h2>
          <p className="text-[#424242] text-md">
            Revoking consent will prevent you from proceeding with the
            application.
          </p>
          <div className="flex flex-col md:flex-row gap-2 justify-center w-full mt-8">
            <Button
              customCss="order-2 md:order-1 w-full font-semibold"
              variant={buttonTypes.SECONDARY}
              onClick={onConfirm}
            >
              Confirm
            </Button>
            <Button
              customCss="order-1 md:order-2 w-full font-semibold"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default ConfirmationModal;
