import ConfirmationModal from "components/Form/ExperianConsentRevokeForm/ConfirmationModal";
import { revokeConsent } from "components/Form/LoanOfferScreen/redux/OfferDetailsSlice";
import { TinySwitch } from "components/Inputs/Switch";
import { consentValidation } from "lib/utils/validations";
import { useRouter } from "next/router";
import { useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

const ExperianConsentRevokeForm = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const { slug } = router.query || {};

  const {
    application: {
      profile: { consent },
    },
  } = useSelector((store) => store?.offerDetails);

  return (
    <Form onSubmit={() => {}} initialValues={{ consent }}>
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} noValidate>
          <fieldset disabled={!consent}>
            <div className="mt-6 flex items-start">
              <div className="inline-block pt-1">
                <Field
                  name="consent"
                  component={TinySwitch}
                  type="checkbox"
                  validate={consentValidation}
                  handleSwitchClick={(checked) => {
                    setShowConfirmationModal(!checked);
                  }}
                />
              </div>
              <div className="text-sm md:text-0.93rem font-semibold text-gray-700 text-left">
                Turn off if you wish to revoke Leap Finance's access to check
                your credit information with Experian.
              </div>
            </div>
            {showConfirmationModal && (
              <ConfirmationModal
                isDialogOpen={showConfirmationModal}
                onClose={() => {
                  form.reset();
                  setShowConfirmationModal(false);
                }}
                onConfirm={() => {
                  setShowConfirmationModal(false);
                  dispatch(revokeConsent(slug));
                }}
              />
            )}
          </fieldset>
        </form>
      )}
    </Form>
  );
};

export default ExperianConsentRevokeForm;
