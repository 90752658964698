import { call, put, select, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { errorMessages } from "lib/utils/constants";
import {
  calculateEmi,
  fetchOfferDetails,
  revokeConsent,
  saveOfferDetails,
  switchToCosignerDetailsStage,
  updateApplication,
  updateCalculatedEmi,
  updateInitialValues,
  updateIsPageLoading,
  updateIsRevoking,
  updateIsSaving,
  updateIsSwitchingStage,
} from "components/Form/LoanOfferScreen/redux/OfferDetailsSlice";
import {
  requestConsentRevoke,
  requestEmiCalculation,
  requestOfferDetails,
  requestSaveOfferDetails,
  requestSwitchToCosignerDetailsStage,
} from "components/Form/LoanOfferScreen/redux/OfferDetailsApi";
import Router from "next/router";
import { eventName, triggerInschoolTrackForm } from "lib/utils/events_utils";
import { trackEvent } from "scripts/segment";
import { fireEvent } from "utils/ga";
import { logEvent } from "components/Analytics/google-analytics";
import { pageRoutes } from "constant/routes";

const getStore = (store) => store?.offerDetails;

function* handleFetchOfferDetails(action) {
  try {
    yield put(updateIsPageLoading(true));
    const res = yield call(requestOfferDetails, action.payload);
    if (res.data) {
      yield put(updateInitialValues(res?.data?.application));
      yield put(updateApplication(res?.data?.application));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsPageLoading(false));
  }
}

function* handleSwitchToCosignerDetailsStage(action) {
  try {
    yield put(updateIsSwitchingStage(true));
    const slug = action.payload;
    const res = yield call(requestSwitchToCosignerDetailsStage, slug);
    if (res?.data?.success) {
      Router.push(`/applications/${slug}/${res?.data?.data?.toLowerCase()}`);
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsSwitchingStage(false));
  }
}

function* handleSaveOfferDetails(action) {
  try {
    yield put(updateIsSaving(true));
    const res = yield call(requestSaveOfferDetails, action.payload);
    const { application } = yield select(getStore);
    yield call(triggerInschoolTrackForm, {
      values: action.payload,
      applicationId: application?.id,
      screen: application?.applicationState?.screen,
      nextStage: res?.success && res?.data,
      loanAmountInUSD: action.payload?.loanAmountInUsd,
    });
    if (res.success) {
      yield call(
        logEvent,
        "Page Completed",
        `${application?.applicationState?.screen} completed`,
        `${application?.slug}`
      );
      yield call(trackEvent, eventName.IS_LOAN_OFFER_ACCEPTED);
      yield call(fireEvent, eventName.IS_LOAN_OFFER_ACCEPTED);
      Router.push(
        `/applications/${action.payload.slug}/${res?.data?.toLowerCase()}`
      );
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsSaving(false));
  }
}

function* handleRevokeConsent(action) {
  try {
    yield put(updateIsRevoking(true));
    const slug = action.payload;
    const res = yield call(requestConsentRevoke, slug);
    if (res?.data?.success) {
      toast.success("Consent Revoked Successfully.", {
        autoClose: 2000,
        onClose: () => {
          Router.push(pageRoutes.INSCHOOL_DASHBOARD);
        },
      });
    } else {
      toast.error(res?.data?.message || errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsRevoking(false));
  }
}

function* handleCalculateEmi(action) {
  try {
    const res = yield call(requestEmiCalculation, action.payload);
    if (res?.data?.success) {
      yield put(updateCalculatedEmi(res?.data?.data));
    } else {
      toast.error(res?.data?.message || errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.log(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  }
}

function* offerDetailsSaga() {
  yield takeLatest(fetchOfferDetails, handleFetchOfferDetails);
  yield takeLatest(
    switchToCosignerDetailsStage,
    handleSwitchToCosignerDetailsStage
  );
  yield takeLatest(saveOfferDetails, handleSaveOfferDetails);
  yield takeLatest(revokeConsent, handleRevokeConsent);
  yield takeLatest(calculateEmi, handleCalculateEmi);
}

export default offerDetailsSaga;
