import apolloClient from "apolloClient";
import { gql } from "@apollo/client";
import axios from "axios";
import ApplicationService from "services/applicationService";
import { inschoolStages } from "constant/inschoolStages";

export const requestOfferDetails = (slug) =>
  apolloClient.query({
    query: gql`
      query($slug: String!) {
        application(slug: $slug) {
          id
          slug
          duration
          minimumMonthlyPayment: minimumMonthlyPayments
          minimumMonthlyPaymentDuration
          country
          cosignerAttempt
          profile {
            consent
            experianStatus
            experianResponse {
              status
              code
              error
              maskedPhone
              bureauScore
              creditAccountTotal
              creditAccountActive
              creditAccountClosed
            }
          }
          cosigner1 {
            experianStatus
            experianResponse {
              status
              code
              error
              maskedPhone
              bureauScore
              creditAccountTotal
              creditAccountActive
              creditAccountClosed
            }
          }
          cosigner2 {
            experianStatus
            experianResponse {
              status
              code
              error
              maskedPhone
              bureauScore
              creditAccountTotal
              creditAccountActive
              creditAccountClosed
            }
          }
          offer {
            loanInterest
            loanAmount
            loanAmountInUSD
            loanTenure
            repaymentType
          }
          applicationState {
            screen
            status
            underwriting
            subStage
          }
        }
      }
    `,
    variables: { slug },
    fetchPolicy: "no-cache",
  });

export const requestSwitchToCosignerDetailsStage = (slug) =>
  axios.get(
    `/api/route?path=in-school/application/switch-cosigner-details/${slug}`
  );

export const requestSaveOfferDetails = (data) => {
  const app = new ApplicationService(data?.slug);
  return app.update(data, inschoolStages.OFFER_DETAILS);
};

export const requestSofrRate = (slug) => {
  const params = slug ? `?slug=${slug}` : "";
  return axios.get(`/api/route?path=in-school/sofr-details/fetch${params}`);
};

export const requestConsentRevoke = (slug) =>
  axios.get(`/api/route?path=in-school/application/revoke-consent/${slug}`);

export const requestEmiCalculation = (data) => axios.post("/api/getEmi", data);
