import axios from "axios";
import { Input } from "components/Inputs/TextField";
import {
  englishTestTypes,
  highestEducationQualificationTypes,
  testMinMaxScores,
} from "components/EducationLoan/constants";
import { AsyncCreatableSelect } from "components/Inputs/MultiSelect";

export const generateAptitudeTestFields = (testType) => {
  const minScore = testMinMaxScores[testType]?.min;
  const maxScore = testMinMaxScores[testType]?.max;
  return {
    quantScoreField: {
      name: "testScoreDetails.aptitudeQuantitativeScore",
      label: `${testType} Score (Quant)`,
      placeholder: `Enter ${testType} Score (Quant)`,
      component: Input,
      type: "number",
      isRequired: true,
      validate: (value) => {
        if (!value && value !== 0) return "This field is required";
        if (value && value.toString().includes("."))
          return "Decimal not allowed";
        if (value < minScore || value > maxScore)
          return `Please enter a valid ${testType} score`;
      },
    },
    verbalScoreField: {
      name: "testScoreDetails.aptitudeVerbalScore",
      label: `${testType} Score (Verbal)`,
      placeholder: `Enter ${testType} Score (Verbal)`,
      component: Input,
      type: "number",
      isRequired: true,
      validate: (value) => {
        if (!value && value !== 0) return "This field is required";
        if (value && value.toString().includes("."))
          return "Decimal not allowed";
        if (value < minScore || value > maxScore)
          return `Please enter a valid ${testType} score`;
      },
    },
  };
};

export const generateEnglishTestField = (testType) => {
  const minScore = testMinMaxScores[testType]?.min;
  const maxScore = testMinMaxScores[testType]?.max;
  return {
    name: `testScoreDetails.languageTestScore`,
    label: `${testType} Score`,
    placeholder: `Enter ${testType} Score`,
    component: Input,
    type: "number",
    validate: (value) => {
      if (!value && value !== 0) return "This field is required";
      if (testType !== englishTestTypes.IELTS && value.toString().includes("."))
        return "Decimal not allowed";
      if (value < minScore || value > maxScore)
        return `Please enter a valid ${testType} score`;
    },
  };
};

export const generateHighestQualificationFields = (educationType) => {
  return {
    collegeName: {
      name: `${educationType?.toLowerCase()}GraduationDetails.college`,
      component: AsyncCreatableSelect,
      label: "College name",
      placeholder: "Enter college name",
      noOptionsMessage: () => "Please type the college name",
      loadingMessage: () => "Loading college list...",
      loadOptions: async (collegeSearched) => {
        if (collegeSearched.length > 0) {
          const {
            data: { colleges },
          } = await axios(`/api/colleges?term=${collegeSearched}`);
          return colleges.map((college) => ({
            label: college,
            value: college,
          }));
        }
      },
      validate: (v) => {
        if (!v) return "College is required!";
      },
    },
    yearOfGraduation: {
      name: `${educationType?.toLowerCase()}GraduationDetails.year`,
      label: "Year of Graduation",
      component: Input,
      type: "number",
      isRequired: true,
      validate: (value) => {
        if (!value) return "This field is required";
        if (
          Number(value) > new Date().getFullYear() + 1 ||
          Number(value) < 1970
        )
          return "Please enter a valid year";
      },
      placeholder: "Enter year of graduation",
    },
    backlog: {
      name: `${educationType?.toLowerCase()}GraduationDetails.backLogCount`,
      label: `Number of Backlogs during ${educationType}`,
      placeholder: `Enter Number of Backlogs during ${educationType}`,
      component: Input,
      type: "number",
      validate: (v) => {
        if (!v && v !== 0) return "This field is required";
        if (v.toString().includes(".")) return "Decimal not allowed";
        if (educationType === highestEducationQualificationTypes.UG) {
          if (v < 0 || v > 9999) return "Please enter a valid value";
        }
        if (educationType === highestEducationQualificationTypes.PG) {
          if (v < 0 || v > 10) {
            return "Please enter a valid value";
          }
        }
      },
    },
    gapYear: {
      name: `${educationType?.toLowerCase()}GraduationDetails.gapYearCount`,
      label: `Number of Gap years during ${educationType}`,
      placeholder: `Enter Number of Gap years during ${educationType}`,
      component: Input,
      type: "number",
      validate: (v) => {
        if (!v && v !== 0) return "This field is required";
        if (v.toString().includes(".")) return "Decimal not allowed";
        if (educationType === highestEducationQualificationTypes.UG) {
          if (v < 0 || v > 9999) return "Please enter a valid value";
        }
        if (educationType === highestEducationQualificationTypes.PG) {
          if (v < 0 || v > 10) {
            return "Please enter a valid value";
          }
        }
      },
    },
  };
};
