import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialValues: {},
  application: {},
  isSwitchingStage: false,
  isSaving: false,
  isPageLoading: false,
  isRevoking: false,
  calculatedEmi: null,
};

export const fetchOfferDetails = createAction("offerDetails/fetchOfferDetails");

export const switchToCosignerDetailsStage = createAction(
  "offerDetails/switchToCosignerDetailsStage"
);

export const saveOfferDetails = createAction("offerDetails/saveOfferDetails");

export const revokeConsent = createAction("offerDetails/revokeConsent");

export const calculateEmi = createAction("offerDetails/calculateEmi");

const offerDetailsSlice = createSlice({
  name: "offerDetails",
  initialState,
  reducers: {
    updateInitialValues(state, action) {
      return { ...state, initialValues: action.payload };
    },
    updateApplication(state, action) {
      return { ...state, application: action.payload };
    },
    updateIsSwitchingStage(state, action) {
      return { ...state, isSwitchingStage: action.payload };
    },
    updateIsSaving(state, action) {
      return { ...state, isSaving: action.payload };
    },
    updateIsPageLoading(state, action) {
      return { ...state, isPageLoading: action.payload };
    },
    updateIsRevoking(state, action) {
      return { ...state, isRevoking: action.payload };
    },
    updateCalculatedEmi(state, action) {
      return { ...state, calculatedEmi: action.payload };
    },
  },
});

export const {
  updateInitialValues,
  updateApplication,
  updateIsSwitchingStage,
  updateIsSaving,
  updateIsPageLoading,
  updateIsRevoking,
  updateCalculatedEmi,
} = offerDetailsSlice.actions;
export default offerDetailsSlice.reducer;
