import { createContext, useState, Fragment, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { useRouter } from "next/router";
import styled from "styled-components";
import { imageBasePath } from "lib/utils/imageUtils";
import ContactDetailsSection from "components/shared/ContactDetailsSection";
import ExperianConsentRevokeForm from "components/Form/ExperianConsentRevokeForm";

const FormContext = createContext();

const IndicatorVisibility = [
  "PERSONAL_DETAILS",
  "PROFESSIONAL_DETAILS",
  "COSIGNER_DETAILS",
  "ADDRESS_DETAILS",
  "DOCUMENTS_UPLOAD",
  "COSIGNER_DOCUMENTS_UPLOAD",
  "EXTRA_COSIGNER_DETAILS",
  "OFFER_CONFIRMATION",
];

const baVisibility = ["personal_info", "documents"];

const ActiveState = {
  COURSE_DETAILS: 0,
  PERSONAL_DETAILS: 1,
  PROFESSIONAL_DETAILS: 2,
  COSIGNER_DETAILS: 3,
  OFFER_DETAILS: 4,
  ADDRESS_DETAILS: 5,
  DOCUMENTS_UPLOAD: 6,
  COSIGNER_DOCUMENTS_UPLOAD: 7,
  EXTRA_COSIGNER_DETAILS: 8,
  OFFER_CONFIRMATION: 9,
};

const baActiveState = {
  personal_info: 0,
  documents: 1,
};

const preOfferIndicator = [
  {
    name: "Personal Details",
    state: "PERSONAL_DETAILS",
    images: {
      inactive: "personal-details-stage.svg",
      ongoing: "personal-details-stage.svg",
      complete: "personal-details-complete.svg",
    },
    pageUrl: "personal_details",
    nextArrow: true,
  },
  {
    name: "Academic Details",
    state: "PROFESSIONAL_DETAILS",
    images: {
      inactive: "book.svg",
      ongoing: "book-ongoing.svg",
      complete: "book-complete.svg",
    },
    pageUrl: "professional_details",
    nextArrow: true,
  },
  {
    name: "Cosigner",
    state: "COSIGNER_DETAILS",
    images: {
      inactive: "co-signer-stage.svg",
      ongoing: "co-signer-ongoing.svg",
      complete: "co-signer-ongoing.svg",
    },
    pageUrl: "cosigner_details",
    nextArrow: true,
  },
  {
    name: "Your offer",
    state: "OFFER_DETAILS",
    images: {
      inactive: "offer-tick.svg",
      ongoing: "offer-tick.svg",
      complete: "offer-tick.svg",
    },
    pageUrl: "offer_details",
    nextArrow: false,
  },
];

const postOfferIndicator = [
  {
    name: "Additional Details",
    state: "ADDRESS_DETAILS",
    images: {
      inactive: "address-ongoing.svg",
      ongoing: "address-ongoing.svg",
      complete: "address-completed.svg",
    },
    pageUrl: "address_details",
    nextArrow: true,
  },
  {
    name: "Documents",
    state: "DOCUMENTS_UPLOAD",
    images: {
      inactive: "sign-contract.svg",
      ongoing: "sign-contract-ongoing.svg",
      complete: "sign-contract-done.svg",
    },
    pageUrl: "documents_upload",
    nextArrow: true,
  },
  {
    name: "Extra Cosigner Details",
    state: "EXTRA_COSIGNER_DETAILS",
    images: {
      inactive: "extra-cosigner-stage.svg",
      ongoing: "extra-cosigner-ongoing.svg",
      complete: "extra-cosigner-ongoing.svg",
    },
    pageUrl: "extra_cosigner_details",
    nextArrow: true,
  },
  {
    name: "Your Sanction",
    state: "OFFER_DETAILS",
    images: {
      inactive: "offer-tick.svg",
      ongoing: "offer-tick.svg",
      complete: "offer-tick.svg",
    },
    pageUrl: "offer_details",
    nextArrow: false,
  },
];

const bankAccountIndicator = [
  {
    name: "Personal Details",
    state: "personal_info",
    images: {
      inactive: "personal-details-stage.svg",
      ongoing: "personal-details-stage.svg",
      complete: "personal-details-complete.svg",
    },
    pageUrl: "personal_info",
    nextArrow: true,
  },
  {
    name: "Submit Documents",
    state: "documents",
    images: {
      inactive: "sign-contract.svg",
      ongoing: "sign-contract-ongoing.svg",
      complete: "sign-contract-done.svg",
    },
    pageUrl: "documents",
    nextArrow: false,
  },
];

const Title = ({ hasLoanBeenOffered, children }) => (
  <h1
    className={`px-5 md:px-0 lg:px-0 text-base ${
      hasLoanBeenOffered ? "text-green-800" : "text-primary"
    } mb-1 tracking-wide`}
  >
    {children}
  </h1>
);

const Subheading = ({ children }) => (
  <p className="px-5 sm:w-form-width md:px-0 md:text-2xl font-semibold md:tracking-tight leading-snug md:leading-none pb-2">
    {children}
  </p>
);

const Description = ({ children }) => (
  <p className="px-5 sm:w-form-width md:px-0 text-gray-1150 md:tracking-tight text-sm leading-snug pb-4">
    {children}
  </p>
);

const Section = ({ children, paddingTop }) => {
  return (
    <SectionContainer
      className="bounding-box bg-white rounded-none md:rounded-lg mb-0 md:pt-6 px-5 pb-10 md:py-10 md:px-12"
      paddingTop={paddingTop}
    >
      {children}
    </SectionContainer>
  );
};

const Fieldset = ({ children, hasSeparator }) => (
  <fieldset
    className={hasSeparator ? "mb-6 pb-8 md:mb-10 md:pb-10 border-b" : ""}
  >
    {children}
  </fieldset>
);

const Legend = ({ last, children }) => (
  <legend
    className={`mb-4 leading-none font-inter text-gray-900 ${
      last ? "md:mb-0" : "md:mb-6"
    }`}
  >
    {children}
  </legend>
);

const FormSection = ({
  title,
  subheading,
  hasLoanBeenOffered = false,
  children,
  application = {},
  description,
  redBackground,
  showExtraCosigner = false,
  isExperianScreen = false,
  marginRequired = false,
  showContactSection = true,
  ...rest
}) => {
  const { applicationState: { screen } = {}, course = {} } = application || {};
  let courseName, collegeName;
  if (course) {
    const { name = "", campus = {} } = course;
    const { college } = campus;
    courseName = name;
    collegeName = college ? college.name : "";
  }
  const router = useRouter();
  const currentScreen = router.query.screen;
  const baScreen = router.query.stage;
  const [isApplicationStateVisible, SetIsApplicationStateVisible] = useState(
    false
  );
  const [activeTab, setActiveTab] = useState("PERSONAL_DETAILS");

  const [isBaStateVisible, setIsBaStateVisible] = useState(false);
  const [activeBaTab, setActiveBaTab] = useState("personal_info");
  useEffect(() => {
    const isVisible =
      currentScreen &&
      IndicatorVisibility.includes(currentScreen.toUpperCase());
    const baIsVisible = baScreen && baVisibility.includes(baScreen);
    if (isVisible && !isExperianScreen) {
      SetIsApplicationStateVisible(true);
      setActiveTab(currentScreen.toUpperCase());
    }
    if (baIsVisible && !isExperianScreen) {
      setIsBaStateVisible(true);
      setActiveBaTab(baScreen);
    }
  }, []);
  return (
    <FormContext.Provider>
      <SectionBackground redBackground={!!redBackground} />
      <div className="max-w-page-width m-auto flex justify-center">
        <PageContainer className="w-full md:w-auto">
          {course &&
            !isExperianScreen &&
            currentScreen !== "course_details" &&
            !isBaStateVisible &&
            rest.screen !== "upload-visa" && (
              <div className="pl-4 md:pl-0 pb-2">
                <span className="text-gray-700 md:text-md text-sm">
                  {courseName ? `${collegeName} - ${courseName}` : collegeName}
                </span>
              </div>
            )}
          {rest.canGoBack && (
            <div className="flex md:pt-6 pb-2 ml-6 go-back">
              <div onClick={rest.handleBack} className="flex cursor-pointer">
                <img
                  className="mr-2 cursor-pointer"
                  src={`${imageBasePath}/assets/images/index/back-arrow.svg`}
                />
                <p className="text-primary">Back</p>
              </div>
            </div>
          )}
          {isApplicationStateVisible &&
            (ActiveState[activeTab] <= 3 ? (
              <StateTrack className="flex">
                {preOfferIndicator.map((_o) => {
                  return (
                    <div key={_o.state} className="flex items-center">
                      <IconContainer
                        key={_o.state}
                        className={`rounded-full md:py-4 px-4 py-1 ${
                          ActiveState[_o.state] < ActiveState[screen] ||
                          currentScreen === _o.pageUrl
                            ? "opacity-1"
                            : "opacity-50"
                        } flex items-center ${
                          activeTab === _o.state ? "bg-green-800" : "bg-white"
                        }`}
                      >
                        <IconImg
                          src={`${imageBasePath}/assets/images/index/${
                            ActiveState[_o.state] < ActiveState[screen]
                              ? _o.images.complete
                              : currentScreen === _o.pageUrl
                              ? _o.images.ongoing
                              : _o.images.inactive
                          }`}
                        />
                        <div
                          className={`leading-none pl-2 text-xs md:text-sm ${
                            activeTab === _o.state
                              ? "text-white"
                              : "text-gray-700"
                          } `}
                        >
                          {_o.name}
                        </div>
                      </IconContainer>
                      {_o.nextArrow ? <HorizontalBar /> : null}
                    </div>
                  );
                })}
              </StateTrack>
            ) : (
              ActiveState[activeTab] >= 5 &&
              ActiveState[activeTab] <= 8 && (
                <StateTrack className="flex">
                  {postOfferIndicator.map((_o) => {
                    if (
                      !showExtraCosigner &&
                      _o.state === "EXTRA_COSIGNER_DETAILS"
                    ) {
                      return;
                    }
                    return (
                      <div key={_o.state} className="flex items-center">
                        <IconContainer
                          key={_o.state}
                          className={`rounded-full md:py-4 px-4 py-1 ${
                            ActiveState[_o.state] < ActiveState[screen] ||
                            currentScreen === _o.pageUrl ||
                            currentScreen === "cosigner_documents_upload"
                              ? "opacity-1"
                              : "opacity-50"
                          } flex items-center ${
                            activeTab === _o.state ||
                            (_o.state === "DOCUMENTS_UPLOAD" &&
                              activeTab === "COSIGNER_DOCUMENTS_UPLOAD")
                              ? "bg-green-800"
                              : "bg-white"
                          }`}
                        >
                          <IconImg
                            src={`${imageBasePath}/assets/images/index/${
                              screen === "COSIGNER_DOCUMENTS_UPLOAD" &&
                              _o.state === "DOCUMENTS_UPLOAD"
                                ? _o.images.ongoing
                                : screen === "COSIGNER_DOCUMENTS_UPLOAD" &&
                                  _o.state === "EXTRA_COSIGNER_DETAILS"
                                ? _o.images.inactive
                                : ActiveState[_o.state] < ActiveState[screen]
                                ? _o.images.complete
                                : currentScreen === _o.pageUrl ||
                                  currentScreen === "cosigner_documents_upload"
                                ? _o.images.ongoing
                                : _o.images.inactive
                            }`}
                          />
                          <div
                            className={`leading-none pl-2 text-xs md:text-sm ${
                              activeTab === _o.state
                                ? "text-white"
                                : "text-gray-700"
                            }`}
                            style={{
                              color: `${_o.state === "DOCUMENTS_UPLOAD" &&
                                activeTab === "COSIGNER_DOCUMENTS_UPLOAD" &&
                                "white"}`,
                            }}
                          >
                            {_o.name}
                          </div>
                        </IconContainer>
                        {_o.nextArrow ? (
                          showExtraCosigner ? (
                            <HorizontalBar />
                          ) : (
                            <HorizontalBar marginX="1" width="100" />
                          )
                        ) : null}
                      </div>
                    );
                  })}
                </StateTrack>
              )
            ))}
          {isBaStateVisible && !isExperianScreen && (
            <StateTrack
              canGoBack={rest.canGoBack}
              className="flex"
              justifyType="center"
              appType="bank"
            >
              {bankAccountIndicator.map((_o) => {
                return (
                  <div key={_o.state} className={`flex items-center`}>
                    <IconContainer
                      key={_o.state}
                      className={`rounded-full md:py-4 px-4 py-1 ${
                        baActiveState[_o.state] < baActiveState[rest.screen] ||
                        rest.screen === _o.pageUrl
                          ? "opacity-1"
                          : "opacity-50"
                      } flex items-center ${
                        activeBaTab === _o.state ? "bg-green-800" : "bg-white"
                      }`}
                    >
                      <IconImg
                        src={`${imageBasePath}/assets/images/index/${
                          baActiveState[_o.state] < baActiveState[rest.screen]
                            ? _o.images.complete
                            : rest.screen === _o.pageUrl
                            ? _o.images.ongoing
                            : _o.images.inactive
                        }`}
                      />
                      <div
                        className={`leading-none pl-2 text-xs md:text-sm ${
                          activeBaTab === _o.state
                            ? "text-white"
                            : "text-gray-700"
                        }`}
                      >
                        {_o.name}
                      </div>
                    </IconContainer>
                    {_o.nextArrow ? (
                      <HorizontalBar marginX="1" width="110" />
                    ) : null}
                  </div>
                );
              })}
            </StateTrack>
          )}
          <div
            className={`py-2 ${rest.screen === "upload-visa" &&
              "pt-16 md:pt-32"}  ${marginRequired && "pt-16 md:pt-32"}`}
          >
            <Title hasLoanBeenOffered={hasLoanBeenOffered}>{title}</Title>
            <Subheading>{subheading}</Subheading>
            {description && <Description>{description}</Description>}
          </div>

          <div
            className={`w-full sm:w-form-width inline-block ${activeTab.toLowerCase()}-screen`}
            style={{ zindex: "2" }}
          >
            {children}
            {showContactSection && <ContactDetailsSection />}
          </div>
        </PageContainer>
      </div>
    </FormContext.Provider>
  );
};

const ExperianSection = ({ applicant, cosigner }) => {
  const {
    bureauScore,
    creditAccountTotal,
    creditAccountActive,
    creditAccountClosed,
  } = applicant || {};
  const [isCallSectionOpen, setCallSectionOpen] = useState(false);
  return (
    <Fragment>
      {!isCallSectionOpen && (
        <div className="w-full text-center my-6">
          <div
            className="font-semibold inline-block bg-white text-sm text-blue-700 py-5 px-8 cursor-pointer rounded mx-auto"
            onClick={() => {
              setCallSectionOpen(true);
            }}
          >
            <div>
              See your{" "}
              <img
                src={`${imageBasePath}/assets/images/experian_Logo.png`}
                className="h-8 inline"
                style={{ marginTop: "-2px" }}
              />{" "}
              details
            </div>
          </div>
        </div>
      )}
      {isCallSectionOpen && (
        <div className="w-full my-6 rounded px-4 md:px-16 ">
          <div className="bg-white text-center py-5">
            <div className="font-semibold text-sm text-gray-700">
              <img
                src={`${imageBasePath}/assets/images/experian_Logo.png`}
                className="h-10 inline align-bottom"
              />
            </div>
            <div className=" text-base text-gray-600 py-3 px-4 md:px-24">
              <div className=" text-sm text-gray-600 pb-3 text-left ">
                Here are the bureau records returned by Experian for the
                applicant
              </div>
              <div className="w-full mx-auto">
                <div className="w-2/3 inline-block font-semibold text-left">
                  Credit Score
                </div>
                <div className="w-1/3 inline-block text-left">
                  {bureauScore || "-"}
                </div>
              </div>
              <div className="w-full mx-auto">
                <div className="w-2/3 inline-block font-semibold text-left">
                  Count of Accounts
                </div>
                <div className="w-1/3 inline-block text-left">
                  {creditAccountTotal || "-"}
                </div>
              </div>
              <div className="w-full mx-auto">
                <div className="w-2/3 inline-block font-semibold text-left">
                  Active Accounts
                </div>
                <div className="w-1/3 inline-block text-left">
                  {creditAccountActive || "-"}
                </div>
              </div>
              <div className="w-full mx-auto">
                <div className="w-2/3 inline-block font-semibold text-left">
                  Closed Accounts
                </div>
                <div className="w-1/3 inline-block text-left">
                  {creditAccountClosed || "-"}
                </div>
              </div>
            </div>
            {!isEmpty(cosigner) && (
              <div className=" text-base text-gray-600 py-3 px-4 md:px-24">
                <div className=" text-sm text-gray-600 pb-3 text-left ">
                  Here are the bureau records returned by Experian for the
                  co-signer
                </div>
                <div className="w-full mx-auto">
                  <div className="w-2/3 inline-block font-semibold text-left">
                    Credit Score
                  </div>
                  <div className="w-1/3 inline-block text-left">
                    {cosigner?.bureauScore || "-"}
                  </div>
                </div>
                <div className="w-full mx-auto">
                  <div className="w-2/3 inline-block font-semibold text-left">
                    Count of Accounts
                  </div>
                  <div className="w-1/3 inline-block text-left">
                    {cosigner?.creditAccountTotal || "-"}
                  </div>
                </div>
                <div className="w-full mx-auto">
                  <div className="w-2/3 inline-block font-semibold text-left">
                    Active Accounts
                  </div>
                  <div className="w-1/3 inline-block text-left">
                    {cosigner?.creditAccountActive || "-"}
                  </div>
                </div>
                <div className="w-full mx-auto">
                  <div className="w-2/3 inline-block font-semibold text-left">
                    Closed Accounts
                  </div>
                  <div className="w-1/3 inline-block text-left">
                    {cosigner?.creditAccountClosed || "-"}
                  </div>
                </div>
              </div>
            )}
            <div className=" text-base text-gray-600 py-3 px-4 md:px-12">
              <ExperianConsentRevokeForm />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export {
  Title,
  Subheading,
  Section,
  Fieldset,
  Legend,
  FormSection,
  FormContext,
  ExperianSection,
  Description,
};

const SectionBackground = styled.div`
  background: ${(props) => (props.redBackground ? "#F5E2E2" : "#dff5f4")};
  height: 350px;
  width: 100%;
  position: absolute;
  top: 0px;
  z-index: -1;
`;

const PageContainer = styled.div`
  padding-top: 40px;
  & .go-back {
    position: absolute;
    left: 0;
    margin-top: 1rem;
    @media (min-width: 1023px) {
      max-width: 1110px;
      margin: 0 auto;
      right: 0;
      margin-top: 1.5rem;
    }
    & > div {
      @media (min-width: 1023px) and (max-width: 1279px) {
        margin-left: 1.5rem;
      }
    }
  }
`;
const StateTrack = styled.div`
  height: 50px;
  min-width: ${(props) => (!props.appType ? "100%" : "")};
  max-width: ${(props) => (!props.appType ? "100%" : "")};
  padding-right: 10px;
  display: flex;
  justify-content: ${(props) =>
    props.justifyType ? props.justifyType : "space-between"};
  margin: 5px 0px;
  margin-top: ${(props) =>
    props.canGoBack || (props?.appType === "bank" && "6rem")};
  @media (min-width: 300px) and (max-width: 767px) {
    padding-left: 1rem;
    overflow-x: auto;
    margin-top: ${(props) => props.canGoBack && "4rem"};
  }
`;

const SectionContainer = styled.div`
  background: white;
  box-shadow: 0px 3px 4px #00000029;
  // padding-top: ${(props) => props.paddingTop && "15px"};
`;

const HorizontalBar = styled.div`
  height: 5px;
  width: ${(props) => (props.width ? props.width + "px" : "")};
  padding: 0px 10px;
  border-top: 1px solid #a2a7ab;
  margin: ${(props) => (props.marginX ? `0px ${props.marginX}px` : "")};
  @media (max-width: 767px) {
    padding: 0px 18px;
  }
`;

const IconImg = styled.img`
  width: 22px;
  height: 18px;
  @media (max-width: 767px) {
    width: 25%;
    height: 100%;
    margin-left: 5px;
  }
`;

const IconContainer = styled.div`
  min-width: 130px;
  height: 80%;
  @media (max-width: 767px) {
    min-width: 120px;
  }
`;
